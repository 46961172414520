//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-656:_9452,_6892,_7052,_6320,_640,_6936,_515,_3864;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-656')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-656', "_9452,_6892,_7052,_6320,_640,_6936,_515,_3864");
        }
      }catch (ex) {
        console.error(ex);
      }